import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import styled from "@emotion/styled";
import css from "@emotion/css";
import { useT } from "../../translations/translator";
import { Link as GatsbyLink } from "gatsby";

import SEO from "../seo";
import Header from "../header";
import Footer from "../footer";
import RawContainer from "../container";

import RawPetal from "../../svgs/petal.svg";
import RawWorld from "../../svgs/around_the_world.svg";
import RawCheap from "../../svgs/cheap.svg";
import RawEcommerce from "../../svgs/ecommerce.svg";
import RawCustom from "../../svgs/custom.svg";
import RawPortfolio from "../../svgs/portfolio.svg";
import RawBlog from "../../svgs/blog.svg";
import RawSpeed from "../../svgs/speed.svg";
import RawScraping from "../../svgs/scrapping.svg";
import RawChatBot from "../../svgs/chat-bot.svg";
import RawFinishingLine from "../../svgs/finishing_line.svg";

import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Loader from "react-spinners/SyncLoader";

import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";

const Link = styled(GatsbyLink)`
  text-decoration: none;
  font-family: "Hightlight";
  color: var(--text-color-hightlight);
`;

const BlogIcon = styled(RawBlog)`
  width: 50%;
  height: auto;
  min-height: 150px;
`;

const PortfolioIcon = styled(RawPortfolio)`
  width: 80%;
  height: auto;
  min-height: 150px;
`;

const World = styled(RawWorld)`
  width: 80%;
  height: auto;
  min-height: 150px;
`;

const Cheap = styled(RawCheap)`
  width: 70%;
  height: 200px;
  min-height: 150px;
`;

const Ecommerce = styled(RawEcommerce)`
  width: 70%;
  height: 200px;
  min-height: 150px;
`;

const Custom = styled(RawCustom)`
  width: 70%;
  height: 200px;
  min-height: 150px;
`;

const Speed = styled(RawSpeed)`
  width: 70%;
  height: 200px;
  min-height: 150px;
`;

const ChatBot = styled(RawChatBot)`
  width: 70%;
  height: 200px;
  min-height: 150px;
`;

const FinishingLine = styled(RawFinishingLine)`
  width: 70%;
  height: 200px;
  min-height: 150px;
`;

const Scrapping = styled(RawScraping)`
  width: 70%;
  height: 200px;
  min-height: 150px;
`;

const Petal = styled(RawPetal, {
  shouldForwardProp: (prop) => prop !== "reverse",
})`
  position: absolute;
  min-height: 500px;
  width: auto;
  z-index: -1;
  top: 120px;

  ${({ reverse }) =>
    !reverse
      ? `
        left: -210px;
        transform: rotate(-10deg);
    `
      : `
        right: -210px;
        transform: rotate(190deg) scaleY(-1);
      `};
`;

const Col = styled.div`
  &:nth-of-type(1) {
    // padding-top: 130px;
    padding-bottom: 40px;
    // min-height: 450px;
  }

  &:nth-of-type(2) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  text-align: ${({ reverse }) => (!reverse ? "left" : "right")};
  width: 100%;

  @media (min-width: 576px) {
    max-width: 50%;
  }
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  height: auto;

  flex-wrap: wrap;
`;

const ColN = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    text-align: justify;
  }

  min-width: 100%;
  padding: 2rem 0;

  @media (min-width: 768px) {
    padding: 0 1rem;
    min-width: 33.3%;
  }
`;

const Grid = styled.div`
  display: grid;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "a a" "b c";
  }
`;

const Cell = styled.div`
  ${({ fullWidth }) =>
    !fullWidth
      ? ""
      : `
  @media (min-width: 768px) {
    grid-area: a;
  }

    p {
      text-align: center!important;
    }
  `}

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    text-align: justify;
    flex: 1;
    min-height: 5rem;
    margin-bottom: 2rem;
  }

  min-width: 100%;
  padding: 2rem 1rem;
`;

const Container = styled(RawContainer)`
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;

  h2,
  h3 {
    padding: 2rem 0 1rem;
    font-weight: bold;
  }

  display: flex;
  flex-wrap: wrap;
  text-align: ${({ reverse }) => (!reverse ? "left" : "right")};
  flex-direction: ${({ reverse }) => (!reverse ? "row" : "row-reverse")};
`;

const Layout = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-rows: auto auto 1fr auto;

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }
`;

const IndexPage = ({ pageContext }) => {
  const { t } = useT();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    subject: "General",
    text: "",
  });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const setSubject = (value) => {
    setData({ ...data, subject: value });
  };

  const sendEmail = (name, email, subject, text) => {
    setLoading(true);
    fetch("https://nu2b28mfq0.execute-api.sa-east-1.amazonaws.com/Prod/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        subject,
        message: `name: ${name}
email: ${email}

${text}`,
      }),
    })
      .catch((e) => {
        toast.error(t("emailFailure"));
        console.error(e);
      })
      .then((result) => {
        toast.success(t("emailSuccess"));
        setData({ name: "", email: "", subject: "General", text: "" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Layout>
      <SEO
        lang={t("language")}
        title={t("homeTitle")}
        description={t("homeDescription")}
        context={pageContext}
      />
      <Header hasHero setSubject={setSubject} />
      <main style={{ overflowX: "hidden" }}>
        <Services setSubject={setSubject} />
        <About setSubject={setSubject} />
        <Contact
          sendEmail={sendEmail}
          data={data}
          handleChange={handleChange}
          isLoading={isLoading}
        />
      </main>
      <Footer />
      <ToastContainer />
    </Layout>
  );
};

const About = () => {
  const { t } = useT();

  return (
    <Container
      id={t("aboutId")}
      css={css`
        padding-bottom: 80px;
        padding-top: 70px;
      `}
    >
      <Row style={{ alignItems: "center", flexDirection: "column" }}>
        <Col>
          <Row style={{ justifyContent: "center" }}>
            <h2>{t("aboutTitle")}</h2>
          </Row>
          <p style={{ textAlign: "center" }}>{t("aboutText")}</p>
        </Col>
        <Col reverse>
          <World aria-hidden />
        </Col>
      </Row>
    </Container>
  );
};

const Contact = ({ data, sendEmail, handleChange, isLoading }) => {
  const { t } = useT();
  let { name, email, subject, text } = data;
  let [recaptcha, setRecaptcha] = useState(false);

  return (
    <Row
      css={css`
        background: var(--color-yellow);
        padding-bottom: 50px;

        label {
          margin-bottom: 20px;
          font-size: 1.2rem;
        }

        label {
          display: block;
          width: 100%;
        }

        label input,
        label textarea {
          display: block;
          width: 100%;
          margin-top: 10px;
          border-radius: 8px;
          padding: 12px;
          border: none;
          border-bottom: 2px solid black;
        }

        form {
          width: 100%;
          max-width: 600px;
          display: grid;
        }
      `}
    >
      <Container id={t("contactId")}>
        <Row style={{ alignItems: "center", flexDirection: "column" }}>
          <form
            onSubmit={(event) => {
              event.preventDefault();

              if (!recaptcha) {
                toast.error(t("areYouHuman"));
                return;
              }

              sendEmail(name, email, subject, text);
            }}
          >
            <Row style={{ justifyContent: "center" }}>
              <h2>{t("contactTitle")}</h2>
            </Row>
            <p>{t("contactText")}</p>
            <label>
              {t("contactFormName")}
              <input
                type="text"
                name="name"
                required
                onChange={handleChange}
                value={name}
              />
            </label>
            <label>
              {t("contactFormEmail")}
              <input
                type="email"
                name="email"
                required
                onChange={handleChange}
                value={email}
              />
            </label>
            <label>
              {t("contactFormBody")}
              <textarea
                rows="10"
                name="text"
                required
                onChange={handleChange}
                value={text}
                style={{
                  resize: "vertical",
                }}
              ></textarea>
            </label>
            <ReCAPTCHA
              style={{
                justifyContent: "flex-end",
                display: "flex",
              }}
              sitekey="6LelBuUZAAAAAEvRFuVty19DOIyrO_kRSC15rb1S"
              onChange={() => {
                setRecaptcha(true);
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                minHeight: 60,
                paddingTop: 20,
              }}
            >
              {!isLoading && (
                <button type="submit"> {t("contactFormSubmit")} </button>
              )}
              {isLoading && <Loader />}
            </div>
          </form>
        </Row>
      </Container>
    </Row>
  );
};

const Description = styled.p`
  max-width: 600px;
  text-align: center;
`;

const Services = ({ setSubject }) => {
  const { t } = useT();

  const images = [FinishingLine, ChatBot, Speed];
  return (
    <Row
      css={css`
        background: var(--color-blue);
      `}
    >
      <Container id={t("serviceId")} reverse>
        <Row style={{ justifyContent: "center" }}>
          <h2>{t("serviceTitle")}</h2>
        </Row>
        <Grid>
          {t("services").map((service, i) => {
            const Image = images[i];

            return (
              <Cell fullWidth={service.fullWidth}>
                <Image aria-hidden />
                <h3>{service.title}</h3>
                <Description>{service.description}</Description>
                <Link
                  className="button"
                  onClick={() => setSubject(service.cta)}
                  to={t("contactLink")}
                >
                  {service.cta}
                </Link>
              </Cell>
            );
          })}
        </Grid>
      </Container>
    </Row>
  );
};

const Overflow = styled.div`
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  z-index: 1;
  text-align: left;

  h2 {
    padding: 0;
    margin: 6px;
  }
`;

const Portfolio = () => {
  const { t } = useT();
  const { image1, image2, image3, image4 } = useStaticQuery(graphql`
    query PortfolioImages {
      image1: file(relativePath: { eq: "1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      image2: file(relativePath: { eq: "2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      image3: file(relativePath: { eq: "3.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      image4: file(relativePath: { eq: "4.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Row
      css={css`
        padding-bottom: 50px;
      `}
    >
      <Container id={t("portfolioId")}>
        <ColN
          css={css`
            justify-content: "center";
          `}
        >
          <h2>{t("portfolioTitle")}</h2>
        </ColN>
        <AwesomeSlider
          className="aws"
          organicArrows={false}
          animation="cubeAnimation"
          css={css`
            position: "relative";
          `}
        >
          <div>
            <Overflow>
              <h2> Project 1</h2>
            </Overflow>
            <Img fluid={image1.childImageSharp.fluid} />
          </div>
          <div>
            <Overflow>
              <h2> Project 2</h2>
            </Overflow>
            <Img fluid={image2.childImageSharp.fluid} />
          </div>
          <div>
            <Overflow>
              <h2> Project 3</h2>
            </Overflow>
            <Img fluid={image3.childImageSharp.fluid} />
          </div>
          <div>
            <Overflow>
              <h2> Project 4</h2>
            </Overflow>
            <Img fluid={image4.childImageSharp.fluid} />
          </div>
        </AwesomeSlider>
      </Container>
    </Row>
  );
};

export default IndexPage;
